import 'react-app-polyfill/ie9' // must be first import. adds support for ie9+
import 'react-app-polyfill/stable'
// Polyfill for ie11 Object methos (e.g., Object.values(), Object.entries())
import 'core-js/features/object'
import React from 'react'
import ReactDOM from 'react-dom'
import { Router } from 'react-router-dom'
import history from '_v2/routes/history'

import App from './App'
import AppProviders from './_v2/contexts/index'
import './App.scss'

import { ApolloProvider, ApolloClient, createHttpLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { cache } from './cache.js'
import config from '_v4/config'

import { AuthProvider } from 'react-oidc-context'

// TODO: Add REACT_APP_APPLICATIONS_API_URI when it is available
const uri = config.ANALYTICS_GRAPHQL_API
  ? config.ANALYTICS_GRAPHQL_API
  : 'http://localhost:5056/graphql'

const httpLink = createHttpLink({ uri })

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('knock_jwtToken')
  const salesDemo = window.localStorage.getItem('sales_demo')

  let headersObj = {
    ...headers,
    authorization: token ? `Bearer ${token}` : '',
  }

  if (salesDemo) {
    headersObj.demo = salesDemo
  }
  // return the headers to the context so httpLink can read them
  return {
    headers: headersObj,
  }
})

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache,
})

const oidcConfig = {
  authority: config.REALPAGE_LOGIN_URL,
  client_id: config.AUTH_CLIENT_ID,
  redirect_uri: config.AUTH_REDIRECT_URL,
  response_type: 'code',
  scope: config.UNIFIED_SCOPE,
}

ReactDOM.render(
  <Router history={history} onUpdate={() => window.Appcues.page()}>
    <AuthProvider {...oidcConfig}>
      <ApolloProvider client={client}>
        <AppProviders>
          <App />
        </AppProviders>
      </ApolloProvider>
    </AuthProvider>
  </Router>,
  document.getElementById('root')
)
